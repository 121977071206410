<template>
  <div class="move-father-box" @click="hideMoveFile(false)" v-show="moveFile">
    <div @click.stop="hideMoveFile(true)" class="move-child-box">
      <div class="move-title">
        <div class="setting-title">
          {{ getString(strings.User_Move_To) }}
        </div>
        <div class="close-setting">
          <img
            class="close-button-img"
            src="../../../assets/img/popupsImg/colse_new.svg"
            @click.stop="hideMoveFile(false, 2)"
          />
        </div>
      </div>
      <div class="move-header">
        <div class="crumbs-box">
          <span
            class="mind-element"
            v-for="(item, index) in crumbsArr"
            :key="index"
            @click="getChildFile(item, index)"
          >
            {{ item.name
            }}<a-icon style="font-size: 14px; margin-left: 4px" type="right" />
          </span>
        </div>
      </div>
      <div class="move-content">
        <div
          class="my-mind-content-1"
          v-if="fileData != null && fileData.length > 0"
        >
          <div v-for="(item, index) in fileData" :key="index">
            <div
              v-if="item.isDir && item.id != shiftOut.id"
              :style="
                index % 2 == 0 ? 'background: #f1f1f1;' : 'background: #f8f8f8;'
              "
              class="my-mind-list"
            >
              <span
                v-if="item.colorCode == 0 || item.colorCode == ''"
                class="fileiconfont my-mind-icon file-color-blue"
                >&#xe761;</span
              >
              <span
                v-if="item.colorCode == 1"
                class="fileiconfont my-mind-icon file-color-yellow"
                >&#xe761;</span
              >
              <span
                v-if="item.colorCode == 2"
                class="fileiconfont my-mind-icon file-color-red"
                >&#xe761;</span
              >
              <span
                v-if="item.colorCode == 3"
                class="fileiconfont my-mind-icon file-color-green"
                >&#xe761;</span
              >
              <span
                v-if="item.colorCode == 4"
                class="fileiconfont my-mind-icon file-color-gray"
                >&#xe761;</span
              >
              <div class="my-mind-list-text">
                <div class="my-mind-main-heading-move">
                  {{ item.name }}
                </div>
                <div class="my-mind-main-subheading-move">
                  {{ item.subtitle }}
                </div>
              </div>
              <div class="my-mind-time-move">
                {{
                  dateFormat(
                    "YYYY/mm/dd HH:MM",
                    new Date(item.createTime * 1000)
                  )
                }}
              </div>
              <div @click="getChildFile(item, index)" class="gray-box"></div>
            </div>
          </div>
        </div>
        <div class="current-location" v-else>
          <img
            src="../../../assets/img/arvhive/currentLocation.svg"
            width="88px"
            alt=""
          />
        </div>
      </div>
      <div class="move-footer">
        <div
          @click.stop="hideMoveFile(false, 2)"
          class="button-class button-class-colse"
        >
          {{ getString(strings.Global_Cancel) }}
        </div>
        <div
          @click.stop="hideMoveFile(false, 1)"
          class="button-class button-class-ok"
        >
          {{ confrimBtnText }}
        </div>
        <!-- <button @click.stop="hideMoveFile(false,1)">确定</button> <button  @click.stop="hideMoveFile(false,2)">取消</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import Template from "../../../views/template/Template.vue";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
export default {
  name: "MyMoveFile",
  props: ["moveFile", "fileData", "crumbsArr", "shiftOut"],
  data() {
    return {
      strings,
      confrimBtnText: getString(strings.Global_Ok),
    };
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    hideMoveFile(show, move) {
      let obj = {};
      (obj.show = show), (obj.move = move);
      this.$emit("hideMoveFile", obj);
    },
    getChildFile(item, index) {
      let obj = {};
      // obj.show = show,
      // obj.move =move
      (obj.item = item), (obj.index = index);
      this.$emit("getChildFile", obj);
    },
    // parentFile(item,index) {
    //     obj.item = item,
    //     obj.index = index
    //     this.$emit('getChildFile', obj);
    // }
  },
  watch: {
    crumbsArr(newVal) {
      if (newVal.length > 1) {
        this.confrimBtnText = getString(strings.Current_Location);
      } else {
        this.confrimBtnText = getString(strings.Global_Ok);
      }
    },
  },
};
</script>

<style lang="less">
.move-father-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);

  .move-child-box {
    width: 520px;
    height: 398px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .move-child-box .move-header {
    height: 50px;
  }
  .move-child-box .move-header {
    padding-top: 10px;
    padding-left: 20px;
  }
  .move-child-box .move-header .mind-element {
    font-size: 16px;
    /* font-weight: 700; */
    cursor: pointer;
  }

  .move-child-box .move-header .mind-element:nth-last-child(1) i {
    display: none;
  }

  .move-child-box .move-content {
    overflow: hidden;
    height: 260px;
    max-height: 260px;
    overflow-y: scroll;
    background: #f8f8f8;
    width: 480px;
    margin-left: 20px;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #bfbfbf;
    }
  }
  .move-child-box .move-footer {
    display: flex;
    justify-content: center;
  }
  .move-child-box .move-footer .button-class {
    margin: 0 20px;
    margin-top: 10px;
    min-width: 60px;
    width: auto;
    padding: 0 10px;
    height: 30px;
    border-radius: 2px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    cursor: pointer;
  }
  .button-class-ok {
    background: #fd492b;
    color: #fff;
  }
  .button-class-colse {
    border: 1px solid #f0f2f8;
    // background-color: #F0F2F8;
  }
  .move-child-box .move-footer .button-class-colse:hover {
    color: #fd492b;
    border: 1px solid #fd492b;
    // background-color: #ff7354;
    // color: #fff;
  }
  .button-class-ok:hover {
    background: #ff7354;
  }

  /* 文件夹颜色开始 */
  .file-color-green {
    color: #0bbb4e;
  }
  .file-color-blue {
    color: #6bb7da;
  }
  .file-color-red {
    color: #ff4d14;
  }
  .file-color-yellow {
    color: #ebcb3c;
  }
  .file-color-gray {
    color: #707070;
  }
  /* 文件夹颜色结束 */

  /* 列表视图 */
  .my-mind-content-1 {
    padding: 0px;
  }

  .my-mind-content-1 .my-mind-list {
    position: relative;
    display: flex;
    height: 60px;
    align-items: center;
    border-bottom: 1px solid #eee;
    width: 100%;
    cursor: pointer;
  }
  .my-mind-content-1 .my-mind-list .my-mind-img {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 31px;
    text-align: center;
    margin-left: 20px;
    /* border: 1px solid #999; */
    /* box-shadow: 0 0 5px #999; */
    border-radius: 6px;
  }

  .my-mind-content-1 .my-mind-list .my-mind-icon {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 41px;
    text-align: center;
    margin-left: 10px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-list-text {
    margin: 0 20px 0 15px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-heading-move {
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-subheading-move {
    width: 100%;
    /* width: 240px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #999;
  }
  .my-mind-content-1 .my-mind-list .my-mind-time-move {
    /* margin-left: auto; */
    margin-right: 10px;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .my-mind-content-1 .gray-box {
    position: absolute;
    /* visibility:hidden; */
    /* z-index: -1; */
    opacity: 0;
  }
  .my-mind-content-1 .my-mind-list:hover .gray-box {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    visibility: visible;
  }

  /* .my-mind-content-1 .my-mind-list:hover .gray-box-active{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.1);
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
} */
  .my-mind-content-1 .more-father-box {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    /* background-color: lime; */
  }
  .my-mind-content-1 .more-function {
    margin-left: auto;
    margin-right: 20px;
    color: #333;
  }
  .my-mind-content-1 .my-mind-time-title {
    /* margin-left: auto; */
    margin-right: 55px;
  }

  .my-mind-content-1 .my-mind-list-head .my-mind-img {
    box-shadow: 0 0 5px #fff;
  }
  .current-location {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .move-title {
    width: 100%;
    height: 38px;
    position: relative;
    padding: 6px 10px 0 20px;
    background: #efefef;
    border-radius: 5px 5px 0 0;
  }
  .setting-title {
    position: absolute;
    font-size: 18px;
    font-weight: 550;
  }
  .close-setting {
    position: relative;
    float: right;
  }

  .close-button-img {
    width: 18px;
    cursor: pointer;
    opacity: 0.5;
  }
  .close-button-img:hover {
    opacity: 0.8;
  }
}
</style>