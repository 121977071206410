<template>
  <div v-if="activityModelAccout">
    <div class="bg-color-shadow" @click="activityModelClose"></div>
    <!-- 这是图片为空的 -->
    <div
      v-show="activityModelAccout && activityContent.image == ''"
      class="activtiy-model"
    >
      <img class="activity-close" @click="activityModelClose" src="../../../static/img/activity/close_model.svg" alt="" />
      <div class="activity-content no-img-activity-content" >
        <div class="activity-content-text" >
          <div class="content-title">{{ activityContent.title }}</div>
          <div
            class="content-content"
          >
            {{ activityContent.content }}
          </div>
                  <a
            class="activity-git-is-now"
            @click="goActivtiy($event)"
            :href="activityContent.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ activityContent.positiveText }}
            <img class="right-arrow" src="../../../static/img/activity/right_arrow.svg" alt="" />
          </a>
        </div>

      </div>
    </div>
    <div
      v-if="activityModelAccout && activityContent.image != ''"
      class="activtiy-model"
    >
      <div class="activity-header-banner">
        <img v-if="activityContent.image" :src="activityContent.image" alt="" />
      </div>
      <img class="activity-close" @click="activityModelClose" src="../../../static/img/activity/close_model.svg" alt="" />
      <div class="activity-content">
        <div class="activity-content-text">
          <div class="content-title">{{ activityContent.title }}</div>
          <div class="content-content">{{ activityContent.content }}</div>
            <a
              class="activity-git-is-now"
              @click="goActivtiy($event)"
              :href="activityContent.link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ activityContent.positiveText }} 
              <img class="right-arrow" src="../../../static/img/activity/right_arrow.svg" alt="" />
            </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import HttpServerConfig from "../../common/HttpServerConfig";
import { postBaseGetPushMessage } from "../../common/netWork/base_api";
export default {
  data() {
    return {
      activityModelAccout: false,
      activityModelAccoutTem:false,
      msgs: [],
      activityContent: {
        id: "",
        image: "",
        title: "",
        content: "",
        positiveText: "",
        link: "",
      },
    };
  },
  mounted() {
    this.getActivtiyModel();
  },
  methods: {
    ...mapMutations(["activtiyModel","isShowShoppingModel","showLoginModel"]),
    getActivtiyModel() {
      postBaseGetPushMessage(null, (res) => {
        if (res.pushMessages) {
          this.msgs = [];
          for (let i = 0; i < res.pushMessages.length; i++) {
            var push = res.pushMessages[i];
            if (localStorage.getItem("push-" + push.id) == "true") {
              continue;
            }
            this.msgs.push(push);
          }
          if (this.msgs.length == 0) {
            this.activityModelAccout = false;
            this.activtiyModel(false);
            return;
          }
          this.showActivity();
        }
      });
    },
    activityModelClose() {
      this.activityModelAccout = false;
      localStorage.setItem("push-" + this.activityContent.id, "true");
      this.showActivity();
    },
    activityModelToHome(newActivity) {
      if (newActivity == true) {
        this.activtiyModel(true);
        return;
      }
      this.activtiyModel(false);
    },
    showActivity() {
      let firstUseTime = localStorage.getItem("firstUseTime");
      if (firstUseTime == null || firstUseTime < 10) {
        return;
      }
      let time = Util.getCurrentTime() - firstUseTime;
      let useTime = time / 1000 / 60 / 60;
      if (useTime < 24) {
        return;
      }
      for (let index = 0; index < this.msgs.length; index++) {
        const element = this.msgs[index];
        if (localStorage.getItem("push-" + element.id) == "true") {
          continue;
        }
        if (element.image) {
          element.image = HttpServerConfig.getMediaSrc(element.image);
        }
        this.activityContent = element;
        this.activtiyModel(true);
        if(this.$store.state.showLogin){//如果显示登录弹窗，关掉登录弹窗之后再显示活动弹窗
          this.activityModelAccoutTem = true;
        }else{
          this.activityModelAccout = true;
        }
        return;
      }
      this.activityModelAccout = false;
      this.activtiyModel(false);
    },
    goActivtiy(event) {
      let link = this.activityContent.link;
      if(link == 'https://www.mindyushu.com/buy.html'){
        event.preventDefault();
        this.isShowShoppingModel(true);
        this.log(link)
      }

      this.activtiyModel(false);
      this.activityModelAccout = false;
      localStorage.setItem("push-" + this.activityContent.id, "true");
      setTimeout(() => {
        this.showActivity();
      }, 100);
    },
  },
  watch: {
    activityModelAccout(newActivity, olActivity) {
      this.activityModelToHome(newActivity);
    },
    //登录弹窗
    "$store.state.showLogin"(newVal) {
      if (!newVal) {
        //如果显示登录弹窗，关掉登录弹窗之后再显示活动弹窗，活动弹窗只显示一次
        this.activityModelAccout = this.activityModelAccoutTem;
        this.activityModelAccoutTem = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.activtiy-model {
  width: 500px;
  min-height: 200px;
  background: #ffffff;
  opacity: 1;
  border-radius: 12px;
  // position: absolute;
  position:fixed;
  z-index: 1000;
  right: 50%;
  top: 25%;
  transform: translate(50%, 0%);
  display: flex;
  justify-content: center;
  // border: 1px solid #ccc;
  .activity-header-banner {
    width: 100%;
    height: 170px;
    position: absolute;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    top: 0;
    left: 0;
    img {
      width: 100%;
      // height: 247px;
    }
  }
  .activity-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .activity-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // position: absolute;
    margin-top: 163px;
    width: 313px;
    .activity-content-text {
      height: 100%;
      text-align: center;
      .content-title {
        font-size: 24px;
        font-family: Noto Sans SC;
        font-weight: bold;
        // line-height: 17px;
        color: #333;
        opacity: 1;
        margin-top: 20px;
        letter-spacing: 1px;
        width: 100%;
      }
      .content-content {
        font-size: 16px;
        font-family: Noto Sans SC;
        font-weight: 520;
        color: #333;
        opacity: 1;
        margin-top: 16px;
        letter-spacing: 1px;
      }


    .activity-git-is-now {
      width: 120px;
      height: 38px;
      background: #FD5537;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 24px;
      cursor: pointer;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-size: 16px;
      font-family: Noto Sans SC;
      color: #ffffff;
      opacity: 1;
      letter-spacing: 1px;
      span {
        font-size: 16px;
        font-family: Noto Sans SC;
        color: #ffffff;
        opacity: 1;
        letter-spacing: 5px;
      }
      .right-arrow{
        width:16px;
        position:relative;
        top:1px

      }
    }
    .activity-git-is-now:hover {
      background-color: #ff7c65;
      transition: 0.5s;
    }
      
    }


  }

  .no-img-activity-content{
    margin-top: 40px;
    margin-bottom: 40px
  }
}
.bg-color-shadow {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  cursor:pointer;
}
</style>