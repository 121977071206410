<template>
  <main>
    <section v-if="steps == 1" @click="createMap()" class="home-mask-box">
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="direction-arrow">
        <img src="../../../assets/img/noviceGuidance/down.svg" alt="新手引导" />
      </div>
      <div class="create-guide">
        <div class="created-each-box created-default">
          <img
            src="../../../assets/img/user/currency/created_mind.svg"
            class="created-each-box-img"
          />
          <span style="top: 2px; position: relative">{{
            $getStringObj.getString($Strings.Establish)
          }}</span>
        </div>
      </div>
      <div class="novice-content">
        <div>
          {{ $getStringObj.getString($Strings.Welcome_To_Mind) }}
          {{ $getStringObj.getString($Strings.Mind_Before)
          }}<span @click.stop="createMap" class="create-map">{{
            $getStringObj.getString($Strings.Establish)
          }}</span
          >{{ $getStringObj.getString($Strings.Try_A_Map) }}
        </div>
        <span class="triangle"></span>
      </div>
      <div class="novice-content" style="bottom: 130px">
        <!-- 点击创建 -->
        <div>
          {{ $getStringObj.getString($Strings.Click_Create) }}
        </div>
        <span class="triangle"></span>
      </div>
      <!-- <span class="shape"> </span> -->
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
    </section>
    <!-- 预加载 -->
    <img
      src="../../../assets/img/noviceGuidance/tutor.svg"
      style="display: none"
      @load="loadImage"
    />
  </main>
</template>
<script>
import { setCookie, getCookie } from "../../../common/netWork/base";

export default {
  data() {
    return {
      steps: 0,
    };
  },
  created() {},
  methods: {
    setNoviceGuidance(val) {
      localStorage.setItem("noviceGuidance", val);
      // setCookie('noviceGuidance', val)
    },
    getSteps() {
      let val = localStorage.getItem("noviceGuidance");
      // let val = getCookie("noviceGuidance");
      if (val) {
        this.steps = val;
      }
    },
    endTutorial() {
      localStorage.setItem("newGuide", "1");
      // setCookie('newGuide', 1)
      this.setNoviceGuidance(0);
      this.getSteps();
    },
    nextSteps() {
      this.steps++;
      this.setNoviceGuidance(this.steps);
    },
    createMap() {
      this.$router.push("/Home/CreatedFile");
      this.nextSteps();
    },
    loadImage() {
      let val = localStorage.getItem("newGuide");
      // let val = getCookie("newGuide");
      if (val) {
        this.setNoviceGuidance(0);
      } else {
        this.steps = 1;
        this.setNoviceGuidance(this.steps);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home-mask-box {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  .novice-tutor {
    position: absolute;
    left: 60px;
    bottom: 60px;
  }
  .direction-arrow {
    position: absolute;
    left: 45px;
    top: 130px;
  }
  .create-guide {
    width: 103px;
    height: 58px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    left: 26px;
    top: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .created-each-box {
      color: #fd492b;
      .created-each-box-img {
        width: 17px;
        margin-right: 10px;
      }
    }
  }
  .novice-content {
    padding: 10px;
    background: #ffffff;
    border-radius: 2px;
    color: #333333;
    font-size: 14px;
    position: absolute;
    left: 320px;
    bottom: 190px;
  }
  .triangle {
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-right-color: #ffffff;
    border-left: 0;
    position: absolute;
    left: -10px;
    bottom: 5px;
  }
  .shape {
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-right-color: #ffffff;
    border-left: 0;
    position: absolute;
    left: 485px;
    bottom: 165px;
  }
  .end-tutorial {
    // width: 56px;
    // height: 28px;
    padding: 4px 7px;
    border: 1px dashed #ffffff;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    left: 320px;
    bottom: 300px;
    cursor: pointer;
  }
}
.create-map {
  color: #fc5d40;
  cursor: pointer;
}
</style>
